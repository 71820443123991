<template>
  <div v-if="categories">
    <ul>
      <li
        v-for="category in categories"
        :key="category.id"
      >
        {{category.name}}
      </li>
    </ul>
  </div>
</template>

<script lang="babel" type="text/babel">
import listDataItem from '@/components/list/mixins/listDataItem'
export default {
  mixins: [listDataItem],
  computed: {
    categories() {
      if(!Array.isArray(this.row.categories)) return null
      if(this.row.categories.length == 0) return null
      return this.row.categories
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
